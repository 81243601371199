import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../graphics/logo.svg';

const hideRoutes = [
    "/"
];

const Header = () => {
    const location = useLocation();
    const [showHeader, toggleHeader] = useState(true);
    useEffect(() => {
        let path = location.pathname;
        if (hideRoutes.includes(path)) toggleHeader(false);
        else toggleHeader(true);
    }, [location]);
    return showHeader ? ( 
        <header>
            <Link to="/">
                <img src={logo} alt="logo" />
            </Link>
        </header>
    ) : (null);
};
 
export default Header;