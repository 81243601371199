import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from './partials/Header';

const Landing = lazy(() => import('./pages/Landing'));
const MarketPlace = lazy(() => import('./pages/MarketPlace'));

function App() {
  return (
    <div className="App">
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/store">
              <MarketPlace />
            </Route>
          </Switch>
        </Suspense>
    </div>
  );
}

export default App;
